@tailwind base;
@tailwind components;
@tailwind utilities;

body{
	@apply p-safe antialiased font-sans;

	/* PWA enhancements */
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

}

html{
  -webkit-overflow-scrolling: touch;
}

#modal {
	z-index: 9999;
}

input[type=text],
input[type=password],
input[type=number],
input[type=tel],
input[type=email] {
	font-size: 16px !important;
}